.downloads-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
  @include vertical-margin(6, bottom);

  .icon-circle {
    color: $white;
    background: $link__colour;
    margin-right: 1.6rem;
    transition: all $transition;
  }

  .download-item {
    background-color: $white;
    border-radius: $el__border-radius;
    margin-bottom: .6rem;
    box-shadow: 0px 0px 5px rgba(44, 49, 67, 0.2);
    transition: box-shadow $transition;

    &>a {
      padding: 1.4rem 1.6rem;
      display: flex;
      align-items: center;
      text-decoration: none;

      &:hover {
        .icon-circle {
          background: hover-colour($link__colour);
        }
      }
    }

    &--collapsed {
      display: none;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 10px rgba(44, 49, 67, 0.2);
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .downloads-list {
    li {
      margin-bottom: .8rem;

      &>a {
        padding: 1.6rem 1.7rem;
      }
    }
  }
}

.step-pagination {
  background-color: $rsrp-light-blue;
  @include vertical-margin(10, top);

  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.step-pagination__cell {
  @include vertical-padding(12, top);
  @include vertical-padding(12, bottom);
  padding-left: $container__gutter--mobile;
  padding-right: $container__gutter--mobile;

  &:nth-child(1) {
    border-bottom: 2px solid $rsrp-blue;
  }
}

.step-pagination__cell--empty {
  display: none;
}

.step-pagination__cell--prev svg {
  transform: rotate(-180deg);
}

.step-pagination__link {
  display: flex;
  align-items: flex-start;
  text-decoration: none;

  svg {
    display: block;
    margin-right: 1.6rem;
  }

  span {
    display: block;
    margin-top: 2px;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .step-pagination .container {
    padding-left: $container__gutter--desktop;
    padding-right: $container__gutter--desktop;
    flex-direction: row;
  }

  .step-pagination__cell {
    padding-left: 0;
    padding-right: 0;
    width: 50%;

    &:nth-child(1) {
      border-bottom: 0;
      border-right: 2px solid $rsrp-blue;
      padding-right: 6.4rem;
    }

    &:nth-child(2) {
      padding-left: 6.4rem;
    }
  }

  .step-pagination__cell--empty {
    display: block;
  }
}

html {
  font-size: 62.5%;
}

body {
  font-family: $base__font-family;
  color: $base__font-colour;
  font-size: $base__font-size--mobile;
  line-height: $base__line-height--mobile;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "pnum" on, "lnum" on, "ss04" on;
  font-variant-numeric: lining-nums;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $heading__font-family;
  letter-spacing: -0.02em;
}

h1,
.h1 {
  color: $h1__colour;
  font-weight: $h1__font-weight;
  font-size: $h1__font-size--mobile;
  line-height: $h1__line-height--mobile;
  @include vertical-margin(9, top);
  @include vertical-margin(5, bottom);
}

h2,
.h2 {
  color: $h2__colour;
  font-weight: $h2__font-weight;
  font-size: $h2__font-size--mobile;
  line-height: $h2__line-height--mobile;
  @include vertical-margin(6, bottom);

  &.section-heading {
    @include vertical-margin(11, bottom);

    &--top-margin {
      @include vertical-margin(16, top);
    }
  }
}

.applied-filters + h2.section-heading--top-margin {
  margin-top: 0;
}

.matrix--heading {
  h2 {
    @include vertical-margin(16, top);
    @include vertical-margin(11, bottom);
  }

  h3 {
    @include vertical-margin(11, top);
    @include vertical-margin(7, bottom);
  }
}

h3,
.h3 {
  color: $h3__colour;
  font-weight: $h3__font-weight;
  font-size: $h3__font-size--mobile;
  line-height: $h3__line-height--mobile;
  @include vertical-margin(7, bottom);
}

h4,
.h4 {
  font-size: $para-heading__font-size;
  line-height: $para-heading__line-height;
  font-weight: $para-heading__font-weight;
  @include vertical-margin(6, bottom);
}

.matrix--text p + h4,
.matrix--text + .matrix--text h4:first-child {
  @include vertical-margin(8, top);
}

.display-title {
  font-weight: $dsp-title__font-weight;
  font-size: $dsp-title__font-size--mobile;
  line-height: $dsp-title__line-height--mobile;
  background-image: linear-gradient($green 100%, rgba(255,255,255,0) 50%);
	background-repeat: repeat-x;
	background-size: 3px 3px;
	background-position: 0 91%;
}

.section--rsrp-blue .display-title {
  background-image: linear-gradient($white 100%, rgba(255,255,255,0) 50%);
}

b, strong {
  font-weight: 600;
}

p {
  @include vertical-margin(6, bottom);
}

a {
  color: $link__colour;
  text-decoration: $link__text-decoration;
  text-decoration-thickness: $link__text-decoration-thickness;
  text-underline-offset: $link__text-underline-offset;
  transition: all $transition;

  &:hover {
    color: hover-colour($link__colour);
  }
}

hr {
  display: block;
  border: 1px solid $primary-colour;
  @include vertical-margin(6, top);
  @include vertical-margin(6, bottom);
}

blockquote {
  margin: 0;
}

.subtitle {
  font-family: $heading__font-family;
  font-size: $standfirst__font-size--mobile;
  line-height: $standfirst__line-height--mobile;
  @include vertical-margin(6, bottom);
  @include last-child-no-bottom-margin;
}

.standfirst {
  color: $standfirst__colour;
  font-family: $heading__font-family;
  font-size: $standfirst__font-size--mobile;
  line-height: $standfirst__line-height--mobile;
  font-weight: $font-weight--bold;
  @include vertical-margin(6, bottom);
  @include last-child-no-bottom-margin;
}

.quote {
  color: $base__font-colour;
  font-family: $heading__font-family;
  font-weight: $h3__font-weight;
  font-size: $h3__font-size--mobile;
  line-height: $h3__line-height--mobile;
  @include vertical-margin(6, bottom);
  @include last-child-no-bottom-margin;
}

.caption {
  font-weight: $caption__font-weight;
  font-size: $caption__font-size--mobile;
  line-height: $caption__line-height--mobile;
  @include vertical-margin(6, bottom);
}

.standfirst + .matrix {
  @include vertical-margin(11, top);
}

// Remove collapsing margins
.matrix--text {
  display: flex;
  flex-direction: column;
  p {
    font-variant-numeric: oldstyle-nums;
    font-feature-settings: "pnum" on, "lnum" off, "ss04" on;
  }
}

.rich-text,
.matrix--text {
  ul,
  ol {
    list-style: none;
    padding: 0 0 0 2rem;
  }

  li {
    position: relative;
    padding-left: 2.8rem;
    margin-bottom: 6px;

    &:before {
      content: "";
      position: absolute;
      left: .2rem;
    }

    &:last-child {
      @include vertical-margin(8, bottom);
    }
  }

  ul li:before {
    display: block;
    width: .8rem;
    height: .8rem;
    top: .8rem;
    background-color: $base__font-colour;
    border-radius: 50%;
  }

  ol {
    counter-reset: dots;

    li:before {
      content: counter(dots, decimal);
      counter-increment: dots;
      display: inline-block;
      font-weight: 700;
    }
  }
}

.subtitle {
  font-size: $standfirst__font-size--mobile;
  line-height: $standfirst__line-height--mobile;
}

.icon-circle {
  width: 4rem;
  height: 4rem;
  flex: 0 0 4rem;
  max-width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.entry-post-date {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  svg {
    margin-right: .8rem;
    margin-bottom: .2rem;
  }
}

.entry-dates {
  display: flex;
  flex-direction: column;
  @include vertical-margin(6, bottom);
}

.go-to-top {
  font-size: $label__font-size;
  line-height: $label__line-height;
  color: $link__colour;
  cursor: pointer;

  svg {
    vertical-align: -1px;
    margin-right: .4rem;
  }

  &:hover {
    color: hover-colour($link__colour);
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  body {
    font-size: $base__font-size--desktop;
    line-height: $base__line-height--desktop;
  }

  h1,
  .h1 {
    font-size: $h1__font-size--desktop;
    line-height: $h1__line-height--desktop;
  }

  h2,
  .h2 {
    font-size: $h2__font-size--desktop;
    line-height: $h2__line-height--desktop;
  }

  h3,
  .h3 {
    font-size: $h3__font-size--desktop;
    line-height: $h3__line-height--desktop;
  }

  .display-title {
    font-size: $dsp-title__font-size--desktop;
    line-height: $dsp-title__line-height--desktop;
    background-size: 5px 5px;
  }

  .standfirst {
    font-size: $standfirst__font-size--desktop;
    line-height: $standfirst__line-height--desktop;
  }

  .quote {
    font-size: $h3__font-size--desktop;
    line-height: $h3__line-height--desktop;
  }

  .subtitle {
    font-size: $standfirst__font-size--desktop;
    line-height: $standfirst__line-height--desktop;
  }

  .icon-circle {
    width: 4.8rem;
    height: 4.8rem;
    flex: 0 0 4.8rem;
    max-width: 4.8rem;
    display: flex;
  }
}

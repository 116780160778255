.related-entries {
  .row {
    @include vertical-padding(8, top);
  }

  .col {
    @include vertical-margin(8, bottom);
  }

  &__card {
    height: 100%;
    background-color: $white;
    border-radius: $el__border-radius;
    overflow: hidden;
    color: $base__font-colour;
    display: flex;
    flex-direction: column;

    .tag {
      border-color: $mid-grey;
      @include vertical-margin(4, bottom);
    }

    .entry-post-date {
      @include vertical-margin(2, top);
    }
  }

  &__card__image {
    flex-shrink: 0;
  }

  &__card__content {
    @include vertical-padding(6, top);
    @include vertical-padding(8, bottom);
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    flex-grow: 1;
  }

  &__title {
    font-size: $standfirst__font-size--mobile;
    line-height: $standfirst__line-height--mobile;
    font-weight: $font-weight--normal;
    @include vertical-margin(3, bottom);

    a {
      text-decoration: none;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .related-entries {
    &__card__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }
  }
}

.matrix--blockquote {
  padding-left: 2rem;

  .blockquote {
    @include vertical-margin(8, bottom);
    display: flex;
    max-width: 680px;

    &__icon {
      flex: 0 0 1.8rem;
      max-width: 1.8rem;
      height: 2.4rem;
      margin-right: 1rem;
    }

    &__content {
      margin-top: .3rem;
    }

    &__quote {
      > svg {
        margin-left: 1rem;
      }
    }

    &__source {
      display: block;
      @include vertical-margin(5, top);
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .matrix--blockquote {
    .blockquote {

      &__icon-close {
        flex: 0 0 4.2rem;
        max-width: 4.2rem;
      }
    }
  }
}

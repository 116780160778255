.home-hero {
  max-width: 700px;
  @include vertical-margin(18, bottom);

  &__title {
    @include vertical-margin(10, bottom);
  }

  &__standfirst {
    @include vertical-margin(10, bottom);
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .home-hero {
    &__standfirst {
      font-size: 3rem;
      line-height: 3.8rem;
    }
  }
}

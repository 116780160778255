.related-content-items {
  @include list-reset();
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3.2rem;
}

.related-content-item .event-meta {
  margin-bottom: 0;
  @include vertical-margin(4, top);
}

a.related-content-item__link {
  color: inherit;
  text-decoration: none;
}

.related-content-item__hero-image {
  width: 100%;
  margin: 0;
  @include vertical-margin(5, bottom);
  padding: 0;
  border-radius: $el__border-radius;

  img {
    display: block;
    width: 100%;
    border-radius: $el__border-radius;
  }
}

.related-content-item__title {
  margin-bottom: 0;
  color: $white;
  font-size: 2rem;
  line-height: 2.6rem;
}

@media (min-width: map-get($breakpoints, sm)) {
  .related-content-items {
    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  }
}

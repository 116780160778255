.hero-image {
  display: block;

  &--standard {
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .hero-image-frame--panorama {
    height: 283px;
  
    .hero-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
.jump-links {
  font-size: 1.6rem;
  line-height: 1.375;
  @include vertical-margin(12, bottom);

  ol {
    margin: 0;
    padding-left: 2.4rem;
    list-style: none;

    li {
      position: relative;
      margin-bottom: .8rem;
    }

    a {
      text-decoration: none;
    }

    .icon {
      position: absolute;
      left: -2.4rem;
      top: 4px;
    }
  }
}

.jump-links__label {
  color: $h3__colour;
  font-size: $para-heading__font-size;
  line-height: $para-heading__font-size;
  font-weight: $para-heading__font-weight;
  @include vertical-margin(6, bottom);
}

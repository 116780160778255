.jump-links-and-sponsors {
  .jump-links {
    margin-top: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .jump-links-and-sponsors {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    &>* {
      margin-top: 0;

      &:first-child {
        margin-right: 4rem;
      }
    }
  }
}
.pagination {
  @include vertical-margin(14, top);
  @include vertical-margin(10, bottom);
  text-align: center;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.pagination__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  align-items: center;
}

.pagination__info {
  display: block;
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: 600;
  @include vertical-margin(6, bottom);
}

.pagination__button {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 .4rem;
  color: $link-blue;
  background-color: $white;
  box-shadow: 0px 0px 5px rgba(44, 49, 67, 0.2);
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: 600;

  &--current,
  &:hover {
    background-color: $navy;
    color: $white;
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

a.pagination__arrow {
  display: inline-block;
  margin: 0 1rem;
  color: $link-blue;

  svg {
    display: block;
  }

  &:hover {
    color: hover-colour($link__colour);
  }

  &--disabled {
    color: $mid-grey;

    &:hover {
      color: $mid-grey;
      cursor: not-allowed;
    }
  }
}

.pagination__arrow--prev svg {
  transform: rotate(-90deg);
}

.pagination__arrow--next svg {
  transform: rotate(90deg);
}

// Hide certain buttons on mobile
@media screen and (max-width: map-get($breakpoints, sm)) {
  .pagination__list--first-item-active li:nth-child(n+4) {
    display: none;
  }

  .pagination__list--second-item-active li:nth-child(n+4) {
    display: none;
  }

  .pagination__list--penultimate-item-active li:nth-child(-n+2) {
    display: none;
  }

  .pagination__list--last-item-active li:nth-child(-n+2) {
    display: none;
  }

  .pagination__list--centred-active {
    li:first-child,
    li:last-child {
      display: none;
    }
  }
}

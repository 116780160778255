.matrix--highlight {
  @include last-child-no-bottom-margin;
  @include vertical-margin(6, top);
  @include vertical-margin(8, bottom);
  padding: 1.6rem 1.8rem 2rem 2.4rem;
  color: $primary-colour;
  font-family: $heading__font-family;
  font-size: $para-heading__font-size;
  line-height: $para-heading__line-height;
  font-weight: $font-weight--normal;
  position: relative;

  &:before {
    content: "";
    width: 0.6rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $primary-colour;
    margin-left: 2px;
  }
}

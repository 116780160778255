.search-form {
  margin: 0;
  display: flex;

  input {
    height: 44px;
    padding-right: 46px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    border: 0;
    flex-grow: 1;
  }

  button {
    flex-shrink: 0;
    padding: 0;
    width: 44px;
    height: 44px;
    border: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $field-input__border-radius;
    border-bottom-right-radius: $field-input__border-radius;
    box-shadow: none;
    background-color: $navy;
    color: $white;

    svg {
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
    }

    // &:hover {
    //   background-color: $navy;
    //   color: $white;
    // }
  }
}

.search-results {
  @include vertical-margin(14, top);
}

.search-results__count {
  @extend h3;
}

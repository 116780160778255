.event-meta,
.entry-meta {
  padding: 0;
  @include vertical-margin(8, top);
  @include vertical-margin(8, bottom);
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr;
  column-gap: 8px;
  row-gap: 4px;

  dt svg {
    display: block;
    margin: 0 auto;
  }

  dd {
    margin: 0;
  }

  // For the price labels
  .event-meta__price + dd {
    display: flex;
    align-items: center;

    .labels {
      margin-left: .4rem;
    }
  }
}

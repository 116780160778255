.listing {
  @include list-reset;
  @include last-child-no-bottom-margin;
  @include first-child-no-top-margin;
}

.matrix--descendants-listing {
  @include vertical-margin(6, top);
  @include vertical-margin(8, bottom);
}

.card {
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0px 0px 5px rgba(44, 49, 67, 0.2);
  border-radius: $el__border-radius;
  overflow: hidden;
  @include vertical-margin(8, bottom);
  transition: box-shadow $transition;
}

.card--link:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(44, 49, 67, 0.2);

  h3 a {
    color: hover-colour($link__colour);
  }
}

.card__image {
  margin: 0;
  width: 100%;
  display: block;
  flex-shrink: 0;

  a,
  img {
    display: block;
    width: 100%;
  }
}

.card__highlight {
  @include vertical-margin(4, bottom);
  margin-top: -1.4rem;

  .label {
    padding-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;

    &:before {
      content: "";
      width: 1.6rem;
      height: 100%;
      position: absolute;
      left: 0;
      transform: translateX(-100%);
      top: 0;
      left: 0;
    }

    &.label--blue:before {
      background-color: $blue;
    }

    &.label--red:before {
      background-color: $red;
    }
  }
}

.card__content {
  padding: 3rem 1.6rem 2.8rem;
  flex-grow: 1;
  max-width: 700px;

  h3 {
    font-size: $standfirst__font-size--mobile;
    line-height: $standfirst__line-height--mobile;
    @include vertical-margin(3, bottom);
  }

  a {
    text-decoration: none;
  }
}

.card__excerpt {
  @include vertical-margin(5, bottom);
}

.card .tags {
  @include vertical-margin(4, bottom);
}

.card .event-meta {
  margin-top: 0;

  dt,
  dd {
    margin-bottom: 0.8rem;
  }
}

// Some exceptions for resource cards
.card--resource {
  .card__image {
    padding: 3rem 1.6rem 0;

    a {
      width: auto;
    }

    img {
      width: 100%;
      max-width: 150px;
      filter: drop-shadow(0px 0px 5px rgba(44, 49, 67, 0.2));
    }
  }
}

// Pinned card
.card--pinned {
  @include vertical-margin(14, bottom);

  .card__content {
    h3 {
      font-size: 2.8rem;
      line-height: 3.2rem;
      @include vertical-margin(7, bottom);
      font-weight: 500;
    }
  }
}

.card--pinned__label {
  display: block;
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-family: $heading__font-family;
  @include vertical-margin(6, bottom);
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .card {
    flex-direction: row-reverse;
    justify-content: flex-end; // inverse
  }

  .card__image {
    width: 30%;

    @supports (object-fit: cover) {
      a {
        display: flex;
        height: 100%;
      }

      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }

  .card__content {
    padding: 3.2rem 2.4rem 4rem;
    h3 {
      font-size: $standfirst__font-size--desktop;
      line-height: $standfirst__line-height--desktop;
    }
  }

  .card__highlight {
    .label:before {
      width: 2.4rem;
    }
  }

  .card .event-meta {
    display: grid;
    grid-template-columns: 20px auto 20px 1fr;
    grid-template-rows: auto auto;

    .event-meta__price,
    .event-meta__price + dd {
      grid-row: 2 / 3;
      margin-bottom: 0;
    }

    .event-meta__price + dd {
      grid-column: 2 / -1;
    }
  }

  .card--resource {
    .card__image {
      padding: 3.2rem 2.4rem 4rem 0;

      img {
        margin: 0 auto;
      }

      // Reset this for resources
      @supports (object-fit: cover) {
        a {
          display: block;
          height: auto;
        }

        img {
          object-fit: initial;
          height: auto;
        }
      }
    }
  }

  .card--pinned {
    .card__content {
      padding: 5.6rem 3.2rem 6.4rem 4.8rem;

      h3 {
        font-size: 4rem;
        line-height: 5rem;
      }
    }

    &.card--resource {
      .card__image {
        padding: 5.6rem 3.2rem 6.4rem 0;

        img {
          margin: 0;
          max-width: 240px;
        }
      }
    }
  }

  .card--pinned__label {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}

.person {
  padding: 2rem 1.6rem 2.8rem;
  background-color: $white;
  border-radius: $el__border-radius;
  display: flex;
  flex-direction: column;
  @include vertical-margin(10, bottom);
  box-shadow: $box-shadow;
}

.matrix--person + .matrix--person {
  margin-top: -17px;
}

.person__headshot {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  background-color: $purple;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: map-get($vertical-spacers--mobile, 5);

  svg {
    display: block;
    width: 40px;
    height: auto;
  }
}

.person__contents {
  padding-top: 2px;
  flex-grow: 1;

  h3 {
    color: inherit;
    font-size: $para-heading__font-size;
    line-height: $para-heading__font-size;
    font-weight: $para-heading__font-weight;
    @include vertical-margin(3, bottom);
  }
}

.person__title {
  display: block;
  font-weight: 600;
  font-size: $base__font-size--mobile;
  line-height: $base__line-height--mobile;
  @include vertical-margin(2, bottom);
}

.person__bio:not(.expanded) {
  .person-bio__body {
    display: none;
  }
}

.person__bio.expanded .person-bio__trigger svg {
  transform: rotate(180deg);
  margin-top: 0;
}

.person-bio__trigger {
  display: inline-flex;
  align-items: center;
  margin: 0;
  @include vertical-margin(5, top);

  svg {
    margin-top: .3rem;
  }
}

.person-bio__body {
  @include vertical-padding(6, top);
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .person {
    padding: 2.8rem 2.8rem 3.2rem;
    flex-direction: row;
  }

  .person--no-bio {
    align-items: center;
  }

  .matrix--person + .matrix--person {
    margin-top: -28px;
  }

  .person__headshot {
    width: 96px;
    height: 96px;
    flex: 0 0 96px;
    max-width: 96px;
    margin: 0 2.4rem 0 0;

    svg {
      width: 48px;
    }
  }

  .person__title {
    font-size: $base__font-size--desktop;
    line-height: $base__line-height--desktop;
  }
}

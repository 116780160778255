.highlight-card {
  @include vertical-margin(5, top);
  @include vertical-margin(11, bottom);
  background-color: $white;
  padding: 2rem 2.4rem;
  border-radius: $el__border-radius;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
}

.highlight-card__icon {
  display: block;
  width: 4rem;
  height: 4rem;
  margin-bottom: map-get($vertical-spacers--mobile, 6);
  flex-shrink: 0;
}

.highlight-card__text {
  color: $base__font-colour;
  flex-grow: 1;

  h2 {
    color: $navy;
    margin: 0;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  h2 + * {
    @include vertical-margin(5, top);
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .highlight-card {
    flex-direction: row;
  }

  .highlight-card__icon {
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 0;
    margin-right: 2.4rem;
  }

  .highlight-card__text {
    padding-top: .8rem;
  }
}

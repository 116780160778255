.lisitng__member-index {
  display: flex;
  flex-wrap: wrap;
  color: $mid-grey;
  font-family: $heading__font-family;
  font-size: $standfirst__font-size--mobile;
  line-height: $standfirst__line-height--mobile;
  @include vertical-margin(16, bottom);

  li {
    padding: 0 1rem;
  }

  a {
    text-decoration: none;
  }
}

.listing__members {
  .card__content {
    max-width: 672px;
  }
}

.listing__members-go-to-top {
  @include vertical-margin(8, top);
  @include vertical-margin(16, bottom);
}

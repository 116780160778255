.member-lookup {
  @include vertical-margin(5, top);
  @include vertical-margin(11, bottom);
  padding: 2rem 2.4rem;
  border-radius: $el__border-radius;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  background-color: $grey-blue;
}

.member-lookup__heading {
  font-weight: 400;
  color: $base__font-colour;
  font-size: $standfirst__font-size--mobile;
  line-height: $standfirst__line-height--mobile;
}

.member-lookup__input-wrapper {
  width: 100%;
  max-width: 424px;
  position: relative;

  input {
    margin-bottom: 0;
    width: 100%;
  }
}

.member-lookup__results {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border: $field-input__border;
  margin-top: -1px;
  border-bottom-right-radius: $field-input__border-radius;
  border-bottom-left-radius: $field-input__border-radius;
  max-height: 110px;
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;

  &:not(.member-lookup__results--active) {
    display: none;
  }

  span {
    padding: $field-input__padding;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid $grey;
    }

    &:not(.member-lookup__empty-result):hover {
      cursor: pointer;
      background-color: $link-blue;
      color: $white;
    }
  }

  .member-lookup__empty-result {
    color: $mid-grey;
  }
}

.member-lookup__selected-member {
  display: flex;
  @include vertical-margin(6, top);
}

.selected-member__icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $green;
  margin: 0 1.6rem 0 0;

  svg {
    width: 17px;
    height: auto;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .member-lookup {
    padding: 4rem;
  }

  .selected-member__icon {
    width: 48px;
    height: 48px;
  }

  .member-lookup__heading {
    font-size: $standfirst__font-size--desktop;
    line-height: $standfirst__line-height--desktop;
  }
}

@mixin button() {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  transition: all $transition;
  background-color: $white;
  color: $link-blue;
  text-decoration: none;
  @include last-child-no-bottom-margin;
  font-size: 1.8rem;
  line-height: 2.6rem;
  padding: .8rem 2.4rem 1rem;
  box-shadow: $box-shadow;
  border-radius: 5rem;
  cursor: pointer;

  > svg {
    margin-top: .2rem;
  }

  > svg:first-child {
    margin-right: 1rem;
  }

  > svg:last-child {
    margin-left: 1rem;
  }

  &:hover {
    color: $navy;
  }

  &:focus-visible {
    background-color: $white;
    color: $navy !important;
    box-shadow: inset 0 0 0 3px #000, 0 0 0 3px $accessibility__highlight;
  }

  &:not(:last-child) {
    margin-right: 0.5em;
  }
}

button,
.button {
  @include button();
}

a.button {
  text-decoration: none;
}

a.cc-btn {
  text-decoration: none;
  border-radius: 5rem;
  &:hover {
    text-decoration: none !important;
  }
}

.button {
  &--pill {
    border-radius: $el__border-radius;
  }

  &--cta {
    background-color: $green;
    color: $navy;
    box-shadow: none;

    &:hover {
      background-color: $green-hover-selected;
    }

    &:focus-visible {
      background-color: $accessibility__highlight;
    }
  }

  &--cta--blue {
    background-color: $navy;
    color: $white;

    &:hover {
      background-color: $navy-dark;
      color: $white;
    }
  }

  &--small {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  &--outline {
    box-shadow: inset 0 0 0 1px $blue;

    &:hover {
      box-shadow: inset 0 0 0 1px $navy;
    }
  }

  &--circle {
    padding: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 100%;

    > svg:first-child,
    > svg:last-child {
      margin: 0;
    }
  }

  &--tag {
    border-radius: $el__border-radius;
    padding: .4rem 1.2rem .6rem;
    box-shadow: inset 0 0 0 1px $blue;
  }

  .tag-checkbox:checked + &--tag,
  &--tag:hover {
    background-color: $link-blue;
    box-shadow: inset 0 0 0 1px $link-blue;
    color: $white;
  }
}

// .button {
//   &--secondary {
//     background-color: $btn__background-colour--secondary;
//     color: $btn__text-colour--secondary;
//     border: 1px solid $btn__border-colour--secondary;

//     &:hover {
//       background-color: $btn__background-colour--secondary;
//       border-color: hover-colour($btn__border-colour--secondary);
//     }
//   }

//   &--bold {
//     font-weight: $btn__font-weight--bold;
//   }

//   &--ghost {
//     background-color: transparent;
//     color: $link-blue;
//     border: 1px solid $link-blue-30-percent;

//     &:hover {
//       border-color: hover-colour($btn__background-colour);
//     }
//   }

//   &--has-icon svg {
//     display: block;
//     margin-right: 1rem;
//     width: 1.6rem;
//     height: auto;
//     transition: all $transition;
//   }

//   &--shadow {
//     box-shadow: 0px 0px 5px rgba(44, 49, 67, 0.2);
//   }

//   &--pill {
//     border-radius: $el__border-radius;
//   }
// }

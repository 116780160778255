table {
  width: 100%;
  font-size: 1.6rem;
  border-spacing: 0;
  @include vertical-margin(8, bottom);
}

th {
  text-align: left;
  text-transform: uppercase;
  border-bottom: 2px solid $dark-grey;
}

td, th {
  padding: 1rem 1rem 1rem 0;
}

td {
  border-bottom: 1px solid lighten($dark-grey, 50%);
}

td.row-actions {
  > * {
    margin-top: .4rem;
    margin-bottom: .4rem;
  }
}

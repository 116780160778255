.pre-header__link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.2rem 2rem;
  font-size: 1.6rem;
  text-decoration: none;
  background-color: $navy;
  color: $white;

  &:hover {
    background-color: $navy-dark;
    color: $white;
    text-decoration: underline;
  }
}

.site-header {
  background-color: $rsrp-blue;
  padding-top: map-get($vertical-spacers--mobile, 11);
  padding-bottom: map-get($vertical-spacers--mobile, 8);

  .container {
    padding: 0;
  }
}

.site-header__top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: $container__gutter--mobile;
  padding-right: $container__gutter--mobile;

  .search-form input {
    width: 200px;
  }
}

.site-logo {
  display: flex;
  align-items: flex-start;

  img {
    display: block;
  }

  img:nth-of-type(1) {
    width: 146px;
    height: 30px;
  }

  img:nth-of-type(2) {
    display: none;
  }
}

.site-logo__divider {
  display: none;
}

.header-search {
  display: none;
}

.mobile-header-actions {
  display: flex;
  align-items: flex-end;

  svg {
    display: block;
    color: $navy;
    height: 17px;
    width: auto;
  }
}

.mobile-nav-button {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  margin-left: 24px;

  > svg:last-child {
    margin: 0;
  }

  .menu--close {
    display: none;
  }

  &[aria-expanded="true"] {
    .menu--open {
      display: none;
    }

    .menu--close {
      display: block;
    }
  }
}

.site-header__bottom {
  position: relative;
}

.main-nav {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: $rsrp-blue;
  padding: 1.2rem 0 3.6rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  li {
    display: flex;
    flex-direction: column;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-grow: 1;
    padding: .8rem $container__gutter--mobile;
    text-decoration: none;
    color: $navy;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.8rem;
    line-height: 2.6rem;

    svg {
      margin-left: 8px;
      flex-shrink: 0;
      transition: all $transition;
    }

    &[aria-expanded="true"] svg {
      transform: rotate(180deg);
    }
  }

  // != level1
  ul ul {
    // display: none;
    padding: 1rem 0;

    a {
      font-weight: 400;
    }
  }
}

.level-2-nav-wrapper {
  display: none;
}

@media (min-width: map-get($breakpoints, md)) {
  .site-header {
    padding-top: map-get($vertical-spacers--desktop, 10);
    padding-bottom: map-get($vertical-spacers--desktop, 9);
    position: relative;

    .container {
      padding-left: $container__gutter--desktop;
      padding-right: $container__gutter--desktop;
    }
  }

  .site-header__top {
    padding: 0;
  }

  .mobile-header-actions {
    display: none;
  }

  .site-logo {
    img:nth-of-type(1) {
      width: 195px;
      height: 40px;
      margin-right: 12px;
    }

    img:nth-of-type(2) {
      display: block;
      width: 236px;
      height: 43px;
      margin-top: 1px;
      margin-left: 12px;
    }
  }

  .site-logo__divider {
    display: block;
    width: 3px;
    height: 40px;
    background-color: $navy;
  }

  .header-search {
    display: block;
  }

  .site-header__bottom {
    position: initial;
    margin-top: map-get($vertical-spacers--desktop, 9);
  }

  .main-nav {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: initial;
    top: initial;
    width: auto;
    padding: 0;

    // Level 1
    > ul {
      flex-direction: row;
      align-items: flex-end;

      > li {
        &:not(:first-child) {
          margin-left: 32px;
        }

        > a {
          padding: 0;
          justify-content: flex-start;
        }
      }
    }

    // != level1
    ul ul {
      padding: 0;

      a {
        padding: .8rem 0;
      }
    }
  }

  .level-2-nav-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 20px 0 72px;
    background-color: $rsrp-blue;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.2);
    z-index: 10;

    .container {
      display: flex;
      justify-content: flex-end;
    }
  }
}

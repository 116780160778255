.dialog {
  display: none;
  flex-direction: column;
  @include last-child-no-bottom-margin;
  background-color: $white;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: $container__gutter--mobile;
  right: $container__gutter--mobile;
  max-height: calc(100vh - #{$container__gutter--mobile * 2});
  z-index: 10;
  padding: 1.6rem;
  border-radius: $el__border-radius;
  overflow: scroll;

  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  fieldset {
    @include vertical-margin(9, bottom);
    overflow: hidden;

    legend {
      @include vertical-margin(4, bottom);
      font-size: 2rem;
      line-height: 2.6rem;
      font-weight: 600;
      color: $navy;
    }
  }
}

.dialog__shade {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, .75);
  z-index: 9;
}

button.dialog__close {
  align-self: flex-end;
  flex-shrink: 0;

  &:not(:last-child){
    margin-right: 0;
  }

  &:hover,
  &:active {
    background-color: $link-blue;
    box-shadow: inset 0 0 0 1px $link-blue;
    color: $white;
  }
}

.dialog__contents {
  overflow: auto;
}

.dialog__controls {
  @include vertical-margin(10, top);
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
}

.applied-filters {
  @include vertical-margin(14, bottom);
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .dialog {
    width: 100%;
    max-width: 960px;
    left: auto;
    right: auto;
    padding: 2.8rem 3.2rem;
  }

  .dialog__contents {
    margin-top: 1.2rem;
  }

  button.dialog__close {
    position: absolute;
    top: 2.8rem;
    right: 3.2rem;
  }
}

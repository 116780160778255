.author {
  display: flex;
  flex-direction: column;
  @include vertical-margin(6, top);
  @include vertical-margin(14, bottom);
}

// Run on
.author + .author {
  margin-top: -28px;
}

.author__headshot {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  background-color: $purple;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
  }

  svg {
    display: block;
    width: 36px;
    height: auto;
  }
}

.author__contents {
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-top: map-get($vertical-spacers--mobile, 5);

  h3 {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .author {
    flex-direction: row;
    align-items: center;
  }

  // Run on
  .author + .author {
    margin-top: -52px;
  }

  .author__headshot {
    width: 96px;
    height: 96px;
    flex-grow: 0;
    flex-shrink: 0;

    svg {
      width: 48px;
    }
  }

  .author__contents {
    margin-top: 6px;
    margin-left: 24px;
    flex-grow: 1;
  }
}

.featured-entries {
  @include list-reset();
}

.featured-entry {
  background-color: $grey-blue;
  border-bottom: 2px solid $mid-grey-light;

  &__heading {
    font-size: $standfirst__font-size--mobile;
    line-height: $standfirst__line-height--mobile;
    font-weight: $font-weight--normal;
  }

  &__title {
    a {
      text-decoration: none;
    }
  }

  .event-meta,
  .entry-meta {
    @include vertical-margin(2, top);
  }

  &__content {
    @include vertical-padding(14, top);
    @include vertical-padding(16, bottom);
  }

  &__first {
    color: $white;
    background-color: $navy-dark;
    border-bottom: none;

    .featured-entry__heading,
    .featured-entry__title {
      color: $white;

      a {
        color: $white;
      }
    }

    .featured-entry__content {
      @include vertical-padding(16, top);
      @include vertical-padding(17, bottom);
    }

    &--with-image--contain {
      .featured-entry__image {
        @include vertical-padding(10, top);
        @include vertical-padding(10, bottom);
      }

      // Icon
      .card__image a > svg {
        width: 120px;
        height: 120px;
      }

      .featured-entry__content {
        padding-top: 0;
      }
    }
  }
}

.featured-entries-all {
  text-align: center;
  background-color: $mid-grey-light;
  @include vertical-padding(11, top);
  @include vertical-padding(11, bottom);
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .featured-entry {
    &__heading {
      font-size: $standfirst__font-size--desktop;
      line-height: $standfirst__line-height--desktop;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .featured-entries {
    display: flex;
    flex-wrap: wrap;
  }

  .featured-entry {
    flex: 0 0 50%;
    border-bottom: none;

    &__content {
      .container {
        max-width: calc(#{$container__max-width} * .5);
      }
    }

    &:nth-child(odd):not(:first-child) {
      border-left: 1px solid $mid-grey-light;

      .featured-entry__content {
        padding-left: 4rem;

        .container {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }

    &:nth-child(even) {
      border-right: 1px solid $mid-grey-light;

      .featured-entry__content {
        .container {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    &__first {
      flex: 0 0 100%;

      .featured-entry__title {
        font-size: $h2__font-size--desktop;
        line-height: $h2__line-height--desktop;
      }

      .featured-entry__content {
        .container {
          max-width: $container__max-width;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &--with-image {
        display: flex;
        flex-wrap: wrap;

        .hero-image {
          object-fit: cover;
          height: 100%;
        }

        .featured-entry__image {
          flex: 0 0 50%;
        }

        .featured-entry__content {
          flex: 0 0 50%;
          padding-left: 4rem;

          .container {
            max-width: calc(#{$container__max-width} * .5625);
            margin-left: 0;
            margin-right: auto;
          }
        }

        &--contain {
          flex-direction: row-reverse;

          .featured-entry__image {
            padding-left: 4rem;
            display: flex;
            align-items: center;
          }

          .featured-entry__content {
            @include vertical-padding(16, top);

            .container {
              max-width: calc(#{$container__max-width} * .5);
              margin-left: auto;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.resource-download {
  @include vertical-margin(6, top);
  @include vertical-margin(6, bottom);
  padding: 1.6rem;
  box-shadow: 0px 0px 5px rgba(44, 49, 67, 0.2);
  border-radius: 8px;
  display: flex;
}

.resource-download__content {
  flex-grow: 1;

  a {
    display: block;
    font-size: 1.8rem;
    line-height: 2.6rem;
    @include vertical-margin(2, bottom);
  }
}

.resource-download__doc-meta {
  font-size: 1.6rem;
  line-height: 2.2rem;
}

.resource-download__cover-image {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 2.1rem 0 0;
  padding: 0;
  width: 58px;

  a {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  img {
    width: 100%;
    height: auto;
    max-height: 100px;
  }
}

.resource-download__icon {
  width: 100%;
  height: auto;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .resource-download__cover-image {
    margin: 0 2.4rem 0 0;
    width: 78px;
  }
}

.tags {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.4rem;
  padding: .4rem 0;

  li,
  > .tag,
  > .button--tag {
    margin: .4rem;
  }
}

.tag {
  display: block;
  padding: 0.8rem 1.2rem 1rem;
  border: 1px solid currentColor;
  font-size: $tag__font-size;
  line-height: $tag__line-height;
  font-weight: $tag__font-weight;
  border-radius: $el__border-radius;
}

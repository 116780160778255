.section--blockquote {
  background-color: $green;

  .blockquote {
    &__icon {
      color: $white;
      background-color: $primary-colour;
      margin-bottom: 1.6rem;
    }

    &__quote {
      font-family: $heading__font-family;
      letter-spacing: -0.01em;
      font-size: $blockquote__font-size--mobile;
      line-height: $blockquote__line-height--mobile;
    }

    &__source {
      display: block;
      @include vertical-margin(5, top);
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .section--blockquote {
    .blockquote {
      display: flex;

      &__content {
        margin-top: .8rem;
      }

      &__quote {
        font-size: $blockquote__font-size--desktop;
        line-height: $blockquote__line-height--desktop;
      }
    }
  }
}

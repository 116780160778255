.section__event-sticky-nav {
  padding: 0;
  border-bottom: 1px solid #fff;
  //show/hide
  position: fixed;
  width: 100%;
  z-index: 10; //higher than ~
  top: -99999px;
  left: -99999px;
  right: 99999px;
  transform: translateY(-100%);
  opacity: 0;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out, top 0s .3s, left 0s .3s, right 0s .3s;
  &.show {
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(0);
    opacity: 1;
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  }

  a {
    color: $white;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: .6rem 0;
  }

  .button {
    color: $navy;
  }

  .icon {
    margin-right: .6rem;
  }

  ul {
    list-style: none;
    margin: .6rem 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    li {
      margin-right: .6rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .section__event-sticky-nav {
    a {
      margin: 1.2rem 0;
    }

    .icon {
      margin-right: 1.2rem;
    }

    ul {
      margin: 1.2rem 0;
    }
  }
}

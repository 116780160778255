.breadcrumb {
  font-size: 1.6rem;
  line-height: 2.2rem;

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    display: flex;
    align-items: baseline;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumb__item__chevron {
  margin-left: 1.2rem;
  display: block;
  color: $mid-grey;
}

.section--navy-dark {
  .breadcrumb a {
    color: $white;
  }

  .breadcrumb__item__chevron {
    color: $white;
  }
}

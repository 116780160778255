.matrix--accordion {
  @include vertical-padding(4, top);
  @include vertical-margin(6, top);
  @include vertical-margin(10, bottom);

  &.expanded .accordion__icon {
    transform: rotate(180deg);
  }
}

.matrix--accordion:not(.expanded) {
  .accordion__body {
    display: none;
  }
}

.matrix--accordion + .matrix--accordion {
  margin-top: map-get($vertical-spacers--mobile, 10) * -1;
}

.accordion {
  box-shadow: $box-shadow;
  border-radius: $el__border-radius;

  &__heading {
    margin: 0;
    color: $link__colour;
  }

  &__trigger {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: initial;
    width: 100%;
    background-color: transparent;
    color: inherit;
    @include vertical-padding(6, top);
    @include vertical-padding(7, bottom);
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    margin-bottom: 0;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    transition: none;
    border-radius: $el__border-radius;

    h3 {
      color: inherit;
      font-size: $para-heading__font-size;
      line-height: $para-heading__line-height;
      margin: 0;
    }

    &:hover {
      color: hover-colour($link__colour);
      background-color: transparent;

      h3 {
        color: hover-colour($link__colour);
      }
    }
  }

  &__icon {
    margin-top: .8rem;
    margin-left: 1.6rem;
    flex: 0 0 1.6rem;
  }

  &__body {
    @include vertical-padding(5, bottom);
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.body--light-blue .accordion {
  background-color: $white;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .matrix--accordion + .matrix--accordion {
    margin-top: map-get($vertical-spacers--desktop, 10) * -1;
  }

  .accordion {
    &__trigger,
    &__body {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }
  }
}

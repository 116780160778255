.discription:last-of-type {
  p:last-child {
    margin-bottom: 0;
  }
}
.description--collapsed {
  display: none;
}

.descriptions {
  button {
    margin-top: .4rem;
  }
}
.blockquote__matrix-home {
  @include vertical-margin(6, top);
  @include vertical-margin(10, bottom);

  .blockquote__image {
    width: 10rem;
    margin-bottom: 2.8rem;
  }

  .blockquote__image-asset {
    display: block;
    border-radius: 50%;
  }

  .blockquote__quote {
    font-family: $heading__font-family;
    font-size: $h3__font-size--mobile;
    line-height: $h3__line-height--mobile;
    font-weight: $h3__font-weight;
    @include vertical-margin(5, bottom);
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .blockquote__matrix-home {
    display: flex;
    align-items: center;
    
    .blockquote__image {
      flex: 0 0 18rem;
      margin-right: 4.8rem;
      margin-bottom: 0;
    }

    .blockquote__quote {
      font-size: $h3__font-size--desktop;
      line-height: $h3__line-height--desktop;
    }
  }
}
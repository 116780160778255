.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, .5);
  align-items: center;
  justify-content: center;
  display: none; // Triggered with JS

  &.loading-modal--active {
    display: flex;
  }
}

.loading-modal__content {
  padding: 1.6rem 2.4rem;
  width: 100%;
  max-width: 400px;
  background-color: $white;
  border-radius: 1.2rem;
  text-align: center;
}

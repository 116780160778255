.download-block {
  display: flex;
  align-items: center;
  @include vertical-margin(6, bottom);
  width: 100%;
  max-width: 624px;
  padding: 1.2rem 1.6rem;
  background-color: $white;
  box-shadow: 0px 0px 5px rgba(44, 49, 67, 0.2);
  border-radius: 8px;

  &:hover {
    box-shadow: 0px 0px 10px rgba(44, 49, 67, 0.2);

    .download-block__icon {
      background-color: $navy-dark;
    }

    .download-block__link {
      color: $navy-dark;
    }
  }

  &:focus-visible {
    background-color: $white;
    color: $navy-dark !important;
    box-shadow: 0px 0px 0px 3px #FFE14D;
    border: 3px solid $navy-dark;

    .download-block__icon {
      background-color: $navy-dark;
    }

    path {
      fill: $white !important;
    }
  }
}

.download-block__icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: $link-blue;
  flex-shrink: 0;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  svg {
    display: block;
    height: auto;
    width: 16px;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .download-block {
    padding: 1.6rem 1.7rem;
  }

  .download-block__icon {
    width: 48px;
    height: 48px;
    margin-right: 1.6rem;
  }
}

.matrix--highlight-block {
  @include vertical-margin(6, top);
  @include vertical-margin(11, bottom);
}

.matrix+.matrix--highlight-block {
  padding-top: 1.6rem;
}

.highlight-block {
  padding: 2rem 1.6rem 1.6rem;
  background-color: $grey-blue;
  border-radius: $el__border-radius;

  &__heading {
    font-family: $heading__font-family;
    font-size: $standfirst__font-size--mobile;
    line-height: $standfirst__line-height--mobile;
  }
}

.body--rsrp .highlight-block {
  background-color: $rsrp-light-blue;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .highlight-block {
    padding: 2.8rem 3.2rem 2rem 2.4rem;

    &__heading {
      font-size: $standfirst__font-size--desktop;
      line-height: $standfirst__line-height--desktop;
    }
  }
}

.matrix--cta {
  @include vertical-margin(6, top);
  @include vertical-margin(11, bottom);
  max-width: 500px;
}

.matrix+.matrix--cta {
  padding-top: 1.6rem;
}

.cta {
  padding: 2rem 1.6rem 3.2rem;
  color: $white;
  background-color: $primary-colour-dark;
  border-radius: $el__border-radius;

  &__heading {
    color: $white;
    font-family: $heading__font-family;
    font-size: $standfirst__font-size--mobile;
    line-height: $standfirst__line-height--mobile;
    font-weight: 400;
  }

  &__link {
    margin-top: 2.4rem;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .cta {
    padding: 2.8rem 3.2rem 4rem 2.4rem;

    &__heading {
      font-size: $standfirst__font-size--desktop;
      line-height: $standfirst__line-height--desktop;
    }

    &__link {
      margin-top: 2.8rem;
    }
  }
}

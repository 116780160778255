.matrix--chart {
  @include vertical-margin(8, bottom);

  .caption {
    display: block;
    @include vertical-margin(4, top);
  }
}

.chart {
  width: 100%;
  margin: 0 auto;
}

.chart--large {
  max-width: 720px;
}

.chart--medium {
  max-width: 540px;
}

.chart--small {
  max-width: 300px;
}

.sponsors {
  display: inline-block;
  @include vertical-margin(10, top);
  @include vertical-margin(11, bottom);
}

.sponsors-label {
  margin-bottom: .8rem;
}

.sponsors-label-bar {
  border-top: 1px solid $divider-line;
}

.sponsor-logos {
  display: inline-block;
  padding-top: 2rem;

  .sponsor-logo-frame {
    display: inline-block;
    width: 8rem;
    margin-right: .6rem;
    @include vertical-margin(4, bottom);

    &:last-child {
      margin-right: 0;
    }
  }
  
  .logo-frame-ratio {
    position: relative;
    padding-top: 72.5%;

  }

  .logo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .sponsor-logos {
    .sponsor-logo-frame {
      width: 9rem;
      margin-right: 1.2rem;
    }
  }
}
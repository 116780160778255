@mixin accessible-focus-state() {
  &:focus-visible {
    outline: none;
    background-color: $accessibility__highlight;
    color: $accessibility__text !important;
    box-shadow: $accessibility__box-shadow;

    path {
      fill: $accessibility__text !important;
    }
  }
}

a {
  @include accessible-focus-state();
}

.visually-hidden {
  position: absolute;
  left: -9999em;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

.section--call-for-papers {
  .icon-circle {
    color: $white;
    background-color: $primary-colour;
    margin-bottom: 1.2rem;
  }

  button {
    margin-top: .4rem;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .call-for-papers {
    display: flex;
  }

  .icon-circle {
    flex: 0 0 4.8rem;
    margin-right: 2.4rem;
  }

  form {
    margin-top: .8rem;
  }
}
.member-logos {
  text-align: center;
  @include vertical-margin(17, top);
  @include vertical-margin(18, bottom);

  &__heading {
    color: $base__font-colour;
    font-size: $standfirst__font-size--mobile;
    line-height: $standfirst__line-height--mobile;
    font-weight: $font-weight--normal;
  }

  &__logos {
    @include list-reset();
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include vertical-margin(10, top);
    @include vertical-margin(10, bottom);
  }

  &__logo {
    height: 4rem;
    flex: 0 0 50%;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  &__logo-asset {
    max-height: 100%;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .member-logos {
    &__heading {
      font-size: $standfirst__font-size--desktop;
      line-height: $standfirst__line-height--desktop;
    }

    &__logo {
      height: 4rem;
      flex: 0 0 33.333333%;
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .member-logos {
    &__logo {
      height: 6rem;
      flex: 1 1 auto;
      margin-top: 3rem;
      margin-bottom: 3rem;
      padding-left: 3.2rem;
      padding-right: 3.2rem;
    }
  }
}
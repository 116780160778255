.agenda-header {
  .button--cta {
    @include vertical-margin(11, bottom);
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .button {
      margin-left: 2rem;
    }
  }
}

.agenda-group {
  background-color: $white;
  border-radius: $el__border-radius;
  overflow: hidden;
  box-shadow: $box-shadow;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
}

.agenda-group__header {
  color: $link__colour;
}

.agenda-group__trigger {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: initial;
  background-color: transparent;
  color: inherit;
  @include vertical-padding(6, top);
  @include vertical-padding(7, bottom);
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  width: 100%;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  transition: none;

  h3 {
    color: inherit;
    font-size: $para-heading__font-size;
    line-height: $para-heading__line-height;
    margin: 0;
  }

  svg {
    margin-top: .8rem;
    margin-left: 1.6rem;
  }
}

.agenda-group__date {
  margin: 0;
  font-size: 2rem;
  line-height: 2.6rem;
  color: $link-blue;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
}

.agenda-group__fulldate {
  color: lighten($base__font-colour, 30%);
  font-weight: 400;
  font-family: $base__font-family;
  margin-left: .5rem;
}

.agenda-group__session-title {
  font-weight: 400;
  font-family: $base__font-family;
  color: $base__font-colour;
  width: 100%;
}

.agenda-group__body {
  @include vertical-padding(5, bottom);
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.agenda-group:not(.agenda-group--exposed) .agenda-group__body {
  display: none;
}

.agenda-group--exposed .agenda-group__trigger svg {
  transform: rotate(180deg);
}

.agenda-item {
  padding: 1.6rem 0;
  // display: grid;
  grid-template-columns: 7rem 1fr;
  column-gap: 2.5rem;
  border-top: 1px solid #B0B5C1;
}

.agenda-item__time > span {
  @include vertical-margin(5, bottom);
  display: inline-block;
  padding: .2rem 1.2rem .4rem;
  border-radius: 0.8rem;
  background-color: $blue;
  font-weight: 600;
  font-size: $base__font-size--desktop;
  line-height: $base__line-height--desktop;
}

.agenda-item__contents {
  padding-top: 2px;
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-family: $base__font-family;

  * {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
  }

  h4 {
    margin: 0;
    font-weight: 600;
  }

  p {
    @include vertical-margin(4, bottom);
  }

  h4 + p {
    @include vertical-margin(6, top);
  }
}

.agenda-item__event-meta {
  @include vertical-margin(4, top);
  @include vertical-margin(4, bottom);
  grid-column: 2 / 3;
}

.agenda-item__options {
  @include list-reset();
  @include vertical-margin(6, bottom);
  grid-column: 2 / 3;
}

.agenda-item__option {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $divider-line;
  @include vertical-padding(5, top);
  @include vertical-margin(5, bottom);
}

.agenda-option__icon {
  @include vertical-margin(4, bottom);

  span {
    width: 3.2rem;
    height: 3.2rem;
    background-color: $blue;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
  }
}

.agenda-item__more-info:not(.expanded) {
  .agenda-item-more-info__body {
    display: none;
  }
}

.agenda-item__more-info.expanded .agenda-item-more-info__trigger svg {
  transform: rotate(180deg);
  margin-top: 0;
}

.agenda-item-more-info__body {
  @include vertical-padding(5, top);
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .agenda-group__trigger,
  .agenda-group__body {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  .agenda-item {
    display: grid;
  }

  .agenda-item__time > span {
    display: block;
    width: 100%;
  }

  .agenda-item__option {
    flex-direction: row;
  }

  .agenda-option__icon {
    margin-bottom: 0;
    margin-right: 2.2rem;
  }

  .agenda-group__session-title {
    width: auto;

    &:before {
      content: "|";
      display: inline-block;
      margin: 0 .8rem;
      color: lighten($base__font-colour, 50%);
    }
  }
}

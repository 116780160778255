.site-footer {
  @include vertical-padding(16, top);
  @include vertical-padding(14, bottom);
  background-color: #001048;
  color: $white;

  a {
    color: inherit;
  }
}

.site-footer__newsletter {
  @include vertical-padding(18, bottom);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 623px;
  margin: 0 auto;

  svg {
    @include vertical-margin(8, bottom);
  }

  h2 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    color: inherit;
  }

  p {
    text-align: center;
    font-size: 2.2rem;
    line-height: 2.8rem;
    font-family: $heading__font-family;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  form {
    @include vertical-margin(10, top);
    width: 100%;
    max-width: 395px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input[type="email"] {
    width: 100%;
    border: none;
    margin-bottom: map-get($vertical-spacers--mobile, 10);
  }
}

.site-footer__rsrp-logo {
  display: block;
  width: auto;
  height: 104px;
  @include vertical-margin(8, bottom);
}

.site-footer__contact-details {
  @include vertical-padding(5, top);
  @include vertical-padding(8, bottom);
  border-top: 1px solid rgba($grey-blue, .5);
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.site-footer__nav,
.site-footer__social-media {
  padding-top: 6px;
  padding-bottom: 10px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.site-footer__nav {
  border-top: 1px solid rgba($grey-blue, .5);
  font-size: 1.8rem;
  line-height: 2.6rem;

  li:not(:last-child) {
    margin-right: 24px;
  }
}

.site-footer__social-media {
  border-top: 1px solid rgba($grey-blue, .5);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: $green;
    color: #001048;
  }

  svg {
    display: block;
  }

  a[href*="linkedin.com"] svg {
    margin-top: -2px;
    margin-left: 2px;
  }

  a[href*="twitter.com"] svg {
    margin-top: 2px;
    margin-left: 2px;
  }


  li:not(:last-child) {
    margin-right: 16px;
  }
}

.site-footer__credits {
  @include vertical-padding(5, top);
  border-top: 1px solid rgba($grey-blue, .5);
  font-size: 1.6rem;
  line-height: 2.2rem;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .site-footer__newsletter {
    h2 {
      font-size: 4.8rem;
      line-height: 5.6rem;
    }

    p {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    form {
      flex-direction: row;
      align-items: stretch;
    }

    input[type="email"] {
      flex-grow: 1;
      margin: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    input[type="submit"] {
      flex-shrink: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: $field-input__border-radius;
      border-bottom-right-radius: $field-input__border-radius;
    }
  }

  .site-footer__nav {
    padding-top: 8px;
    padding-bottom: 16px;
  }

  .site-footer__social-media {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .site-footer__credits {
    flex-direction: row;
    justify-content: space-between;
  }
}

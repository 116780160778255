.labels {
  display: flex;
  flex-wrap: wrap;
  margin: -.4rem;
}

.label {
  display: inline-flex;
  align-items: center;
  font-size: $label__font-size;
  line-height: $label__line-height;
  padding: .4rem 1.2rem .6rem 1.2rem;
  border-radius: .4rem;
  margin: .4rem;

  > svg {
    margin-right: .8rem;
    display: block;
  }
}

.label--blue {
  background-color: $blue;
  color: $base__font-colour;
}

.label--green {
  background-color: $green;
  color: $base__font-colour;
}

.label--orange {
  background-color: $orange;
  color: $base__font-colour;
}

.label--purple {
  background-color: $purple;
  color: $base__font-colour;
}

.label--pink {
  background-color: $pink;
  color: $base__font-colour;
}

.label--red {
  background-color: $red;
  color: $base__font-colour;
}

.label--navy {
  background-color: $navy;
  color: $white;
}

@use "sass:math";
@use "sass:color";

@import "reset/normalize";
@import "reset/reset";

@import "base/font-face";

@import "utility/helpers";
@import "utility/variables";
@import "utility/accessibility";
@import "utility/print";

@import "base/base";
@import "base/typography";
@import "base/buttons";
@import "base/forms";
@import "base/table";

@import "layout/bootstrap-grid-v5/bootstrap-grid";
@import "layout/container";

@import "researchSupervisionRecognitionProgramme/components/site-header";
@import "components/site-footer";
@import "components/jump-links";
@import "components/alert";
@import "components/section";
@import "components/hero-image";
@import "components/entry-meta";
@import "components/agenda";
@import "components/description";
@import "components/sponsor-logos";
@import "components/event-sticky-nav";
@import "components/jump-links-and-sponsors";
@import "components/tags";
@import "components/label";
@import "components/highlight-card";
@import "components/card";
@import "components/dialog";
@import "components/blockquote";
@import "components/related-entries";
@import "components/footnotes";
@import "components/search";
@import "components/author";
@import "components/members-directory";
@import "components/home-hero";
@import "components/home-body";
@import "components/featured-entries";
@import "components/member-logos";
@import "components/pagination";
@import "components/resource-download";
@import "components/breadcrumb";
@import "components/member-lookup";
@import "components/imported-html";
@import "components/loading-modal";
@import "researchSupervisionRecognitionProgramme/components/step-pagination";

@import "matrix/highlight";
@import "matrix/blockquote";
@import "matrix/blockquote-home";
@import "matrix/accordion";
@import "matrix/keynote-speakers";
@import "matrix/objectives";
@import "matrix/downloads";
@import "matrix/freeform-embed";
@import "matrix/call-for-papers";
@import "matrix/related-content.scss";
@import "matrix/chart";
@import "matrix/html-embed";
@import "matrix/stats";
@import "matrix/cta";
@import "matrix/highlight-block";
@import "matrix/signpost-link";
@import "matrix/video";
@import "matrix/image";
@import "matrix/person";
@import "matrix/download";

body.body--light-blue {
  background: $rsrp-light-blue;
}

//---------//
// Colours //
//---------//

// Brand colours
$navy: #002994;
$navy-dark: #00227A;
$white: #fff;
$off-white: #FBFCFF;
$dark-grey: #2C3143;
$mid-grey: #8790B0;
$mid-grey-light: #E1E5EA;
$grey-blue: #F0F1F4;
$mid-grey-blue: #E7E8EE;
$grey: #F4F3F3;
$grey-50-percent: rgba($grey, 0.5);
$green: #8DF2DA;
$green-hover-selected: #79f3d6;
$red: #FFA8A8;
$purple: #D0CCFF;
$blue: #B7E5FD;
$salmon: #FFD3BA;
$link-blue: #3C72FF;
$link-blue-30-percent: rgba($link-blue, 0.3);
$cream: #FEEADC;
$divider-line: #BAC9E0;
$box-shadow: 0px 0px 5px rgba($dark-grey, 0.2);

$rsrp-blue: #aadffd;
$rsrp-light-blue: #EEF9FF;

// Colour assignment
$primary-colour: $navy;
$primary-colour-dark: $navy-dark;
$secondary-colour: $green;

// Accessibility focus state colours
$accessibility__highlight: #FFE14D;
$accessibility__text: #000;
$accessibility__warning: #D80000;
$accessibility__box-shadow: 0 0 0 3px $accessibility__highlight;

//--------//
// Layout //
//--------//

// Breakpoints
$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// Container
$container__max-width: 1008px;
$container__sm-width: 720px;
$container__gutter--mobile: 1.6rem;
$container__gutter--desktop: 2.4rem;
$container-inner__max-width: 720px;

// Vertical spacers
$vertical-spacers--mobile: (
  1: 2px,
  2: 4px,
  3: 8px,
  4: 10px,
  5: 14px,
  6: 16px,
  7: 18px,
  8: 20px,
  9: 24px,
  10: 28px,
  11: 32px,
  12: 36px,
  13: 40px,
  14: 44px,
  15: 48px,
  16: 52px,
  17: 56px,
  18: 60px
);

$vertical-spacers--desktop: (
  1: 2px,
  2: 4px,
  3: 8px,
  4: 12px,
  5: 16px,
  6: 20px,
  7: 24px,
  8: 28px,
  9: 32px,
  10: 40px,
  11: 48px,
  12: 56px,
  13: 64px,
  14: 72px,
  15: 80px,
  16: 96px,
  17: 112px,
  18: 128px
);

//------------//
// Typography //
//------------//

// Weights
$font-weight--normal: 400;
$font-weight--bold: 500;
$font-weight--heavy: 600;
$font-weight--black: 700;

// Base - Body-M
$base__font-colour: $dark-grey;
$base__font-family: 'Open Sans', sans-serif;
$base__font-size--mobile: 1.8rem;       // 18px
$base__line-height--mobile: 1.444;   // 26px
$base__font-size--desktop: 1.8rem;      // 18px
$base__line-height--desktop: 1.444;  // 26px

// Headings
$heading__font-family: 'Lexend', sans-serif;

// H1 - Heading-XL
$h1__colour: $primary-colour;
$h1__font-weight: $font-weight--bold;
$h1__font-size--mobile: 2.8rem;
$h1__line-height--mobile: 3.2rem;
$h1__font-size--desktop: 4.8rem;
$h1__line-height--desktop: 5.6rem;

// H2 - Heading-L
$h2__colour: $primary-colour;
$h2__font-weight: $font-weight--bold;
$h2__font-size--mobile: 2.8rem;
$h2__line-height--mobile: 3.2rem;
$h2__font-size--desktop: 4rem;
$h2__line-height--desktop: 5rem;

// H3 - Heading-M
$h3__colour: $primary-colour;
$h3__font-weight: $font-weight--bold;
$h3__font-size--mobile: 2.4rem;
$h3__line-height--mobile: 3rem;
$h3__font-size--desktop: 3rem;
$h3__line-height--desktop: 3.8rem;

// Paragraph heading
$para-heading__font-size: 2rem;
$para-heading__line-height: 2.6rem;
$para-heading__font-weight: $font-weight--bold;

// Standfirst
$standfirst__colour: $primary-colour;
$standfirst__font-size--mobile: 2.2rem;
$standfirst__line-height--mobile: 2.8rem;
$standfirst__font-size--desktop: 2.4rem;
$standfirst__line-height--desktop: 3.2rem;

// Caption
$caption__font-weight: $font-weight--normal;
$caption__font-size--mobile: 1.6rem;
$caption__line-height--mobile: 2.2rem;

// Blockquote
$blockquote__font-weight: $font-weight--normal;
$blockquote__font-size--mobile: 2.2rem;
$blockquote__line-height--mobile: 1.273;
$blockquote__font-size--desktop: 2.3rem;
$blockquote__line-height--desktop: 1.333;

// display title
$dsp-title__font-weight: $font-weight--bold;
$dsp-title__font-size--mobile: 2.8rem;
$dsp-title__line-height--mobile: 3.2rem;
$dsp-title__font-size--desktop: 6rem;
$dsp-title__line-height--desktop: 7rem;

// Tags
$tag__font-size: 1.6rem;
$tag__line-height: 1.0;
$tag__font-weight: $font-weight--normal;

// Labels (i.e. small tags)
$label__font-size: 1.6rem;
$label__line-height: 2.2rem;

// Links
$link__colour: $link-blue;
$link__text-decoration: underline;
$link__text-decoration-thickness: 1px;
$link__text-underline-offset: 3px;
$link__text-decoration-thickness--hover: 3px;
$link__text-decoration-thickness--focus: 4px;

//---------//
// Elements //
//---------//

$el__border-radius: .8rem;


//-------//
// Forms //
//-------//

$field-input__padding: .6rem 1.2rem;
$field-input__padding--focus: .5rem 1.2rem;
$field-input__border: 1px solid $link-blue;
$field-input__border-radius: .4rem;
$field-input__border--focus: 2px solid $primary-colour;

//-------//
// Alert //
//-------//

$alert--primary__colour: $primary-colour;
$alert--danger__colour: #dc3545;

//-------------------------//
// Animation & interaction //
//-------------------------//

$transition__duration: 0.2s;
$transition__curve: ease;
$transition: $transition__duration $transition__curve;

$hover__effect: darken; // darken or lighten
$hover__effect-amount: 30%;

.section__footnotes h4 {
  @include vertical-margin(8, top);
}

sup.footnote {
  color: $blue;
  font-weight: 600 !important;

  a {
    color: $link-blue;
    text-decoration: none;

    &:before {
      content: '[';
      color: $link-blue;
      padding-left: 4px;
    }

    &:after {
      content: ']';
      color: $link-blue;
    }

    &:hover, &:focus {
      color: $navy;

      &:before {
        color: $navy;
      }

      &:after {
        color: $navy;
      }
    }
  }
}

ol.footnotes {
  list-style: none;
  margin: 0;
  padding: 0;

  .footnote {
    display: flex;
    @include vertical-margin(2, bottom);

    a[name] {
      font-weight: 700;
      color: $link-blue;
      text-decoration: none;
      margin-right: 1.4rem;
    }
  }
}

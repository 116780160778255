.stats {
  @include vertical-margin(6, top);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: $primary-colour;

  &__stat {
    @include vertical-margin(4, top);
    @include vertical-margin(10, bottom);
    padding: .4rem 0 1rem 2.2rem;
    position: relative;

    &:before {
      content: "";
      width: 0.6rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: $secondary-colour;
      margin-left: 2px;
    }
  }

  &__statistic {
    font-size: 5rem;
    line-height: 5rem;
    font-family: $heading__font-family;
    margin-bottom: .8rem;
  }

  &__text {
    font-size: $label__font-size;
    line-height: $label__line-height;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .stats {
    flex-direction: row;

    &__stat {
      flex: 0 0 50%;
    }

    &__statistic {
      font-size: 6rem;
      line-height: 6rem;
    }

    &__text {
      padding-right: 1.6rem;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .stats {
    &__stat {
      flex: 0 0 33.333333%;
    }
  }
}

.objective--collapsed {
  display: none;
}

.section--objectives.section--navy-dark {
  ol {
    list-style: none;
    padding: 0;
    counter-reset: dots;
  }

  li {
    position: relative;
    @include vertical-margin(5, bottom);
    padding-left: 4.4rem;
    min-height: 2.8rem;

    span {
      display: block;
      padding-top: 3px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      content: counter(dots, decimal);
      counter-increment: dots;
      font-weight: 700;
      width: 2.8rem;
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $green;
      color: $base__font-colour;
      border-radius: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.section--objectives {
  button {
    margin-top: .4rem;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .section--objectives.section--navy-dark {
    li {
      padding-left: 4.8rem;
      min-height: 3.2rem;

      &:before {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }
}

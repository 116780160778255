input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea,
.StripeElement {
  display: block;
  margin-bottom: 1.4rem;
  line-height: 1.4;
  padding: $field-input__padding;
  border: $field-input__border;
  border-radius: $field-input__border-radius;
  background-color: $white;
  outline: none;

  &:hover {
    border-color: $primary-colour;
  }

  &:focus {
    padding: $field-input__padding--focus;
    border: $field-input__border--focus;
    box-shadow: $accessibility__box-shadow;
  }
}

.StripeElement {
  padding: .9rem 1rem;
}

// input[type=checkbox],
input[type=radio] {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  border: $field-input__border;
  background-color: $white;
  cursor: pointer;
  appearance: none;

  &:hover {
    border-color: $primary-colour;
  }

  &:focus {
    border: $field-input__border--focus;
    box-shadow: $accessibility__box-shadow;
  }
}

input[type=checkbox] {
  margin-right: 1rem;
  // border-radius: $field-input__border-radius;

  // &:checked {
  //   color: $primary-colour;
  //   border: $field-input__border--focus;

  //   &:before {
  //     content: '';
  //     position: absolute;
  //     display: block;
  //     width: 42.5%;
  //     height: 67.5%;
  //     top: .1rem;
  //     left: .6rem;
  //     border-bottom: 3px solid;
  //     border-right: 3px solid;
  //     transform: rotate(45deg);
  //   }
  // }
}

input[type=radio] {
  border-radius: 50%;

  &:checked {
    color: $primary-colour;
    border: $field-input__border--focus;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      // top: 50%;
      // transform: translateY(-50%);
      // left: 0;
      // right: 0;
      // margin: 0 auto;
      top: .6rem;
      left: .6rem;
      background-color: $primary-colour;
      border-radius: 50%;
    }
  }
}

.radio-label {
  display: flex;
  align-items: flex-start;
  position: relative;

  input[type="radio"] {
    margin-right: 1rem;
    flex-shrink: 0;
  }

  span {
    flex-grow: 1;
  }
}

label {
  display: inline-block;
  margin-bottom: .4rem;
  font-size: $base__font-size--mobile;
  line-height: $base__line-height--mobile;

  &.large {
    margin-bottom: 1.2rem;
    font-family: $heading__font-family;
    font-size: $standfirst__font-size--mobile;
    line-height: $standfirst__line-height--mobile;
    font-weight: $font-weight--bold;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
  appearance: none;
}

.vertical-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include vertical-margin(6, bottom);
}

// freeform
form .freeform-pages {
  width: 100%;
  margin: 0;
  @include vertical-margin(8, bottom);
  display: flex;
  border-bottom: 1px solid lighten($dark-grey, 50%);
  list-style: none;
  padding: 0;

  li {
    padding: 1rem 1rem 1rem 0;
  }
}

.freeform-row {
  max-width: 760px;
}

.freeform-column {
  display: flex;
  flex-direction: column;

  button,
  .button {
    margin: 1rem auto 1rem 0;
  }

  &[style*="display: block"] {
    display: flex !important;
  }
}

.freeform-column.freeform-column--submit {
  flex-direction: row;

  button,
  .button {
    margin: 0 2rem 0 0;
  }
}

.freeform-input-only-label {
  position: relative;
  padding-left: 4rem;

  input[type=checkbox] {
    position: absolute;
    left: 0;
    top: 7px;
  }
}

.freeform-instructions {
  @include vertical-margin(3, bottom);
  font-size: $caption__font-size--mobile;
  line-height: $caption__line-height--mobile;
}

.radio-group,
.freeform-column--checkbox_group {
  margin-bottom: 1.4rem;
}

.freeform-column--radio_group {
  label:not(.freeform-label) {
    position: relative;
    padding-left: 4rem;
  }

  input[type=radio] {
    position: absolute;
    left: 0;

    &:before {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .input-group-one-line {
    label {
      margin-right: 4rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.ff-form-success {
  @include vertical-margin(5, top);
  @include vertical-margin(11, bottom);
  background-color: $green;
  padding: 2rem 2.4rem;
  border-radius: $el__border-radius;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px rgba(44, 49, 67, 0.2);

  p {
    margin: 0;
    color: $navy;
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-family: $heading__font-family;
  }
}

.ff-form-errors {
  color: $accessibility__warning;
}

.ff-errors {
  color: $accessibility__warning;
  margin: -1rem 0 1.4rem 0;
  padding-left: 0;
  list-style: none;

  li {
    margin-bottom: .8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.freeform-label.freeform-required:after {
  content: "*";
  margin-left: .8rem;
  color: red;
  position: absolute;
}

#cookieConsentForm {
  button[type="submit"] {
    margin-top: 2rem;
  }
}

.freeform-file-drag-and-drop {
  margin-bottom: 1.4rem;
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select,
  textarea,
  .radio-group,
  .freeform-column--checkbox_group,
  .freeform-file-drag-and-drop,
  .StripeElement {
    margin-bottom: 2.8rem;
  }

  label {
    margin-bottom: .8rem;
    font-size: $base__font-size--desktop;
    line-height: $base__line-height--desktop;

    &.large {
      margin-bottom: 2rem;
      font-size: $standfirst__font-size--desktop;
      line-height: $standfirst__line-height--desktop;
      // font-weight: $font-weight--bold;
    }
  }

  .freeform-column {
    button,
    .button {
      margin: 1.6rem auto 1.6rem 0;
    }
  }

  .ff-errors {
    margin: -2rem 0 2.8rem 0;
  }
}

@mixin alert-colours($colour) {
  background-color: color.scale($colour, $lightness: 90%, $saturation: -50%);
  border-color: $colour;
  color: $colour;
}

.alert {
  @include vertical-margin(2, top);
  @include vertical-margin(6, bottom);
  padding: 1rem 1.5rem;
  border: 1px solid;
  font-size: $caption__font-size--mobile;
  line-height: $caption__line-height--mobile;
  font-weight: $font-weight--bold;

  a {
    color: inherit;
  }

  ul {
    margin: 0;
    padding: 0 0 0 1.5rem;
  }
}

.alert--primary {
  @include alert-colours($alert--primary__colour);
}

.alert--danger {
  @include alert-colours($alert--danger__colour);
}

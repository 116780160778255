.section--padding {
  @include vertical-padding(16, top);
  @include vertical-padding(17, bottom);
}

#sectionFilter {
  padding-bottom: 0;
}

.section--navy-dark {
  background-color: $navy-dark;
  color: $white;

  h1,
  h2 {
    color: inherit;
  }

  ol {
    li {
      &:before {
        color: $white;
      }
    }
  }
}

.section--grey {
  background-color: $grey-blue;
  border-top: 2px solid $white;

  &--no-border {
    border: none;
  }
}

.section--rsrp-light-blue {
  background-color: $rsrp-light-blue;
}

.section--rsrp-blue {
  background-color: $rsrp-blue;
}

.section--no-padding {
  padding: 0;
}

.matrix--blockquote + .section--grey {
  border-top: 0;
}

.event-sections .section:first-child:not(.section--grey, .section--navy-dark) {
  position: relative;

  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    width: 100%;
    max-width: calc(#{$container__max-width} - #{$container__gutter--mobile * 2});
    height: 1px;
    background-color: $divider-line;

    @media (min-width: map-get($breakpoints, sm)) {
      max-width: calc(#{$container__max-width} - #{$container__gutter--desktop * 2});
    }
  }
}

.section__hero {
  @include vertical-padding(16, top);
  @include vertical-padding(14, bottom);

  .entry-post-date {
    @include vertical-margin(7, top);
  }
}

.section__body {
  @include vertical-margin(10, top);
  @include vertical-margin(18, bottom);
  display: flex;
  flex-direction: column;
}

.section__listing {
  @include vertical-margin(16, top);
  @include vertical-margin(18, bottom)
}

.section__breadcrumb {
  @include vertical-padding(10, top);
}

.section__footnotes {
  @include vertical-padding(7, top);
  @include vertical-padding(18, bottom);
}

.section__body + .section__footnotes {
  margin-top: -2.8rem;
  @media screen and (min-width: map-get($breakpoints, sm)) {
    margin-top: -8rem;
  }
}

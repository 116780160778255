.container {
  width: 100%;
  max-width: $container__max-width;
  padding-left: $container__gutter--mobile;
  padding-right: $container__gutter--mobile;
  margin-left: auto;
  margin-right: auto;
}

.container-inner {
  width: 100%;
  max-width: $container-inner__max-width;
}

.container-800 {
  width: 100%;
  max-width: 800px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .container {
    padding-left: $container__gutter--desktop;
    padding-right: $container__gutter--desktop;
  }
}

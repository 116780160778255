.matrix--image {
  @include vertical-margin(8, top);
  @include vertical-margin(8, bottom);
}

.content-image {
  display: block;
  border-radius: .4rem;

  &__caption {
    @include vertical-margin(4, top);
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

iframe,
img,
video,
svg {
  max-width: 100%;
}

.matrix--video {
  @include vertical-margin(8, top);
  @include vertical-margin(8, bottom);
}

.video {
  position: relative;
  padding-top: 56.25%;
  background-color: black;
  border-radius: .4rem;
  overflow: hidden;

  &__asset {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    padding: 0;
    background-color: black;
    border-radius: .4rem;
    border: none;
    cursor: pointer;
  }

  &__image {
    display: block;
    transition: all $transition;
  }

  &__play-icon {
    width: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    .video__button {
      color: $link__colour;
    }

    .video__image {
      opacity: .6;
    }
  }

  &__playing {
    .video__image,
    .video__button {
      display: none;
    }
  }

  &__caption {
    @include vertical-margin(4, top);
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .video {
    &__play-icon {
      width: 4.8rem;
    }
  }
}
.matrix--signpost-link {
  margin-top: 2.6rem;
  margin-bottom: 2.8rem;
}

.matrix--signpost-link + .matrix--signpost-link {
  margin-top: -.8rem;
}

.signpost-link {
  display: flex;

  &__icon {
    width: 2.8rem;
    margin-right: 1.6rem;
    color: $link__colour;
    transition: all $transition;
  }

  &__content {
    margin-top: .3rem;
  }

  &__link {
    text-decoration: none;
  }

  &__link-source,
  &__doc-meta {
    font-size: $label__font-size;
    line-height: $label__line-height;
  }

  &:hover {
    .signpost-link__icon {
      color: hover-colour($link__colour);
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .matrix--signpost-link {
    margin-top: 2.4rem;
    margin-bottom: 4rem;
  }

  .matrix--signpost-link + .matrix--signpost-link {
    margin-top: -2.4rem;
  }

  .signpost-link {
    display: flex;

    &__icon {
      width: 3.2rem;
    }
  }
}
